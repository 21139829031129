/* eslint-disable no-empty-pattern */
import { VARCHAR_MAX_LENGTH, VARCHAR_MIN_LENGTH } from 'app/constants';
import { commonService, customerReviewService } from 'app/services';
import validatorChain from 'app/utils/validator-chain';
import { HttpStatusCode } from 'axios';
import { InputText } from 'primereact';
import { InputTextarea } from 'primereact/inputtextarea';
import { forwardRef, memo, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line react/prop-types
function AddEditCustomerReview({ onChange }, refs) {
    const { t } = useTranslation();
    const [ID, setID] = useState({});
    const [mode, setMode] = useState(false);
    const [Name, setName] = useState('');
    const [Content, setContent] = useState('');
    const [Point, setPoint] = useState('');
    const [Address, setAddress] = useState('');
    const [error, setError] = useState({});
    const [show, setShowModal] = useState(false);
    useImperativeHandle(refs, () => ({
        /**
         * show dialog edit/add review
         * @param {Object} data
         */
        show: (data) => {
            if (!data.mode) {
                setID(data.ID);
                setName(data.Name);
                setPoint(data.Point);
                setContent(data.Content);
                setAddress(data.Address);
            }
            setMode(data.mode);
            setShowModal(true);
        }
    }));
    /**
     * Reset data
     */
    const initData = () => {
        setName('');
        setPoint('');
        setContent('');
        setAddress('');
        setError({});
    };
    /**
     * Validate data
     * @returns Boolean
     */
    const validateData = async () => {
        const errors = {
            Name: validatorChain(Name.trim())
                .isRequire('M001')
                .isLength({ min: VARCHAR_MIN_LENGTH, max: VARCHAR_MAX_LENGTH }, 'M003')
                .getError(),
            Point: validatorChain(Point.trim())
                .isRequire('M001')
                .isLength({ min: VARCHAR_MIN_LENGTH, max: VARCHAR_MAX_LENGTH }, 'M003')
                .getError(),
            Address: validatorChain(Address.trim())
                .isRequire('M001')
                .isLength({ min: VARCHAR_MIN_LENGTH, max: VARCHAR_MAX_LENGTH }, 'M003')
                .getError(),
            Content: validatorChain(Content.trim()).isRequire('M001').isLength({ min: VARCHAR_MIN_LENGTH, max: 5000 }, 'M010').getError()
        };
        setError(errors);
        return Object.values(errors).reduce((r, c) => (c === null ? r : false), true);
    };
    /**
     * Add review
     */
    const addReview = async () => {
        if (await validateData()) {
            const res = await customerReviewService.create({
                Name: Name.trim(),
                Point: Point.trim(),
                Address: Address.trim(),
                Content: Content.trim()
            });
            if (res.status === HttpStatusCode.Ok) {
                commonService.toast.show({
                    severity: 'success',
                    summary: t('INFO_MESSAGE'),
                    detail: t('M019')
                });
                setShowModal(false);
                initData();
                onChange(true);
            } else {
                commonService.toast.show({
                    severity: 'error',
                    summary: t('ERROR_MESSAGE'),
                    detail: t('M009')
                });
            }
        }
    };
    /**
     * Update review
     */
    const editReview = async () => {
        if (await validateData()) {
            const res = await customerReviewService.update({
                ID,
                Name: Name.trim(),
                Point: Point.trim(),
                Address: Address.trim(),
                Content: Content.trim()
            });
            if (res.status === HttpStatusCode.Ok) {
                commonService.toast.show({
                    severity: 'success',
                    summary: t('INFO_MESSAGE'),
                    detail: t('M012')
                });
                setShowModal(false);
                initData();
                onChange(true);
            } else {
                commonService.toast.show({
                    severity: 'error',
                    summary: t('ERROR_MESSAGE'),
                    detail: t('M011')
                });
            }
        }
    };
    /**
     * Hide dialog
     */
    const onCancel = async () => {
        initData();
        setShowModal(false);
    };
    return (
        <Modal show={show} centered size='md' backdrop='static' className={mode ? 'modal-edit-error' : 'modal-edit-error read-input'}>
            <Modal.Body className='p-0'>
                <h2 className='text-primary text-center fw-bold pt-4 fs-24'>
                    {mode ? t('LABEL_TITLE_ADD_CUSTOMER_REVIEW') : t('LABEL_TITLE_EDIT_CUSTOMER_REVIEW')}
                </h2>
                <div className='col-12 row py-2 mx-0 px-4 pt-5 centered-control'>
                    <div className='col-3 ps-0'>
                        <label className='fs-md w-100 ps-3'>{t('TABLE_LABEL_NAME')}</label>
                    </div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <InputText
                            id='name'
                            value={Name}
                            className='px-2 fs-md'
                            placeholder={t('PLACEHOLDER_ENTER_NAME')}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                </div>
                <div className='col-12 row mx-0 px-4 centered-control pb-4'>
                    <div className='col-3 ps-0'></div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <label className='break-work text-danger'>{t(error['Name'])}</label>
                    </div>
                </div>
                <div className='col-12 row py-2 mx-0 px-4 centered-control'>
                    <div className='col-3 ps-0'>
                        <label className='fs-md w-100 ps-3'>{t('TABLE_LABEL_ADDRESS')}</label>
                    </div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <InputText
                            id='Address'
                            value={Address}
                            className='px-2 fs-md'
                            placeholder={t('PLACEHOLDER_ENTER_ADDRESS')}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </div>
                </div>
                <div className='col-12 row mx-0 px-4 centered-control pb-4'>
                    <div className='col-3 ps-0'></div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <label className='break-work text-danger'>{t(error['Address'])}</label>
                    </div>
                </div>
                <div className='col-12 row py-2 mx-0 px-4 centered-control'>
                    <div className='col-3 ps-0'>
                        <label className='fs-md w-100 ps-3'>{t('TABLE_LABEL_ADDRESS')}</label>
                    </div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <InputTextarea
                            id='Content'
                            value={Content}
                            className='px-2 fs-md'
                            placeholder={t('PLACEHOLDER_ENTER_CONTENT')}
                            onChange={(e) => setContent(e.target.value)}
                        />
                    </div>
                </div>
                <div className='col-12 row mx-0 px-4 centered-control pb-4'>
                    <div className='col-3 ps-0'></div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <label className='break-work text-danger'>{t(error['Content'])}</label>
                    </div>
                </div>
                <div className='col-12 row py-2 mx-0 px-4 centered-control'>
                    <div className='col-3 ps-0'>
                        <label className='fs-md w-100 ps-3'>{t('LABEL_POINT')}</label>
                    </div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <InputText
                            id='point'
                            value={Point}
                            className='px-2 fs-md'
                            placeholder={t('PLACEHOLDER_ENTER_POINT')}
                            onChange={(e) => setPoint(e.target.value)}
                        />
                    </div>
                </div>
                <div className='col-12 row mx-0 px-4 centered-control pb-4'>
                    <div className='col-3 ps-0'></div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <label className='break-work text-danger'>{t(error['Point'])}</label>
                    </div>
                </div>
                <div className='d-flex justify-content-center gap-2 py-4 border-top'>
                    <Button className='btn-w-120 rounded border btn-cancel-sm' variant='default' onClick={onCancel}>
                        {t('BTN_CANCEL')}
                    </Button>
                    <Button
                        className='btn-w-120 rounded text-white fw-bold btn-action-sm'
                        variant='default'
                        onClick={mode ? addReview : editReview}>
                        {t('BTN_OK')}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default memo(forwardRef(AddEditCustomerReview));
