/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { GROUP, IMAGE_TYPE } from 'app/constants';
import { carouselService, commonService, fileManagerService } from 'app/services';
import DefaultImage from 'assets/image/default.png';
import { HttpStatusCode } from 'axios';
import { Image } from 'primereact/image';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';
export default function Carousel() {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    let currentID = '';
    /**
     * fetch data from api
     * @param {Object} input
     * @returns
     */
    const fetchData = async () => {
        commonService.preloader.show();
        const data = await carouselService.get();
        setData(data);
        commonService.preloader.hide();
        return true;
    };
    /**
     * Handle Delete Action
     * @param {Number} ID ID action
     */
    const remove = async (ID) => {
        const result = await commonService.modal.showConfirm({
            header: t('LABEL_DELETE_ITEM'),
            message: t('M016'),
            img: 'delete-message',
            customClass: 'warning-confirm-delete px-0'
        });
        if (result) {
            commonService.preloader.show();
            const res = await carouselService.removeImage({ ID });
            if (res.status === HttpStatusCode.Ok) {
                commonService.toast.show({
                    severity: 'success',
                    summary: t('INFO_MESSAGE'),
                    detail: t('M018')
                });
                fetchData();
                commonService.preloader.hide();
                return true;
            }
            commonService.preloader.hide();
            return commonService.toast.show({
                severity: 'error',
                summary: t('ERROR_MESSAGE'),
                detail: t('M017')
            });
        }
    };
    /**
     * Fetch first render data
     */
    useEffect(() => {
        commonService.preloader.hide();
        fetchData();
    }, []);

    // eslint-disable-next-line no-unused-vars
    const onUpload = () => {
        const file = document.createElement('INPUT');
        file.setAttribute('type', 'file');
        file.accept = 'image/x-png, image/gif, image/jpeg, image/webp';
        file.setAttribute('single', true);
        file.onchange = handleUpload;
        file.click();
    };
    const handleUpload = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const files = event.target.files || event.dataTransfer.files;
        const data = new FormData();
        data.append('Type', GROUP.CAROUSEL);
        data.append('Category', currentID?.Type || IMAGE_TYPE.CAROUSEL);
        data.append('ID', currentID?.ID);
        data.append('Name', currentID?.Image);
        data.append('files', files[0]);
        await fileManagerService.upload(data);
        commonService.toast.show({
            severity: 'success',
            summary: t('INFO_MESSAGE'),
            detail: t('M007')
        });
        fetchData();
    };
    const getListRestaurantImage = () => {
        const list = [];
        const listImage = data.filter((v) => v.Type !== IMAGE_TYPE.CAROUSEL);
        for (const item of listImage) {
            const image = !_.isNull(item.Image) ? `${process.env.PUBLIC_URL}/image/home/header/${item.Image}` : DefaultImag;
            const value = (
                <div className='col-md-4 col-sm-12 px-1 py-3' key={item.ID}>
                    <div className='mx-2'>
                        <div className='item-carousel'>
                            <Image src={image} alt='' preview />
                        </div>
                        <div className='events-body px-3'>
                            <div className='entity-header'>
                                <h2 className='event-title py-3 text-center'>
                                    <span>
                                        {item.Type === IMAGE_TYPE.RESTAURANT
                                            ? t('LABEL_RESTAURANT')
                                            : item.Type === IMAGE_TYPE.CABANON_RESTAURANT
                                              ? t('LABEL_CABANON_RESTAURANT')
                                              : ''}
                                    </span>
                                </h2>
                            </div>
                            <div className='row mx-0 justify-content-center align-items-center pb-3'>
                                <Button
                                    className='btn-cancel-sm w-80px mx-2'
                                    variant='default'
                                    onClick={(e) => {
                                        currentID = item;
                                        e.preventDefault();
                                        onUpload();
                                    }}>
                                    {t('BTN_EDIT_IMAGE')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
            list.push(value);
        }
        return list;
    };
    const getListImageHomePage = () => {
        const list = [];
        const listImage = data.filter((v) => v.Type === IMAGE_TYPE.CAROUSEL);
        for (const item of listImage) {
            const image = !_.isNull(item.Image) ? `${process.env.PUBLIC_URL}/image/home/carousel/${item.Image}` : DefaultImage;
            const value = (
                <div className='col-md-4 col-sm-12 px-1 py-3' key={item.ID}>
                    <div className='mx-2'>
                        <div className='item-carousel'>
                            <Image src={image} alt='' preview />
                        </div>
                        <div className='events-body px-3'>
                            <div className='entity-header'>
                                <h2 className='event-title py-3 text-center'>
                                    <span>Carousel</span>
                                </h2>
                            </div>
                            <div className='row mx-0 justify-content-center align-items-center pb-3'>
                                <Button
                                    className='btn-cancel-sm w-80px mx-2'
                                    variant='default'
                                    onClick={(e) => {
                                        currentID = item;
                                        e.preventDefault();
                                        onUpload();
                                    }}>
                                    {t('BTN_EDIT_IMAGE')}
                                </Button>
                                <Button
                                    className={item.Type === IMAGE_TYPE.CAROUSEL ? 'btn-action-sm w-80px' : 'd-none'}
                                    variant='default'
                                    onClick={() => remove(item.ID)}>
                                    {t('LABEL_TOOLTIP_ICON_REMOVE')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
            list.push(value);
        }
        return list;
    };
    return (
        <div className='events px-3'>
            <div className='row'>
                <div className='col-12 row mx-0 py-2'>
                    <div className='w-75 py-2 row mx-0 px-0'>
                        <h1 className='my-0 fs-xxl fw-bold text-primary w-100 px-0 align-items-center d-flex'>
                            {t('LABEL_LIST_IMAGE_HOMEPAGE')}
                        </h1>
                    </div>
                    <div className='w-25 px-0 d-flex justify-content-end'>
                        <Button key='Upload' variant='secondary' onClick={() => onUpload(true)} className='btn-action-sm'>
                            {t('BTN_ADD_IMAGE')}
                        </Button>
                    </div>
                    {getListImageHomePage()}
                </div>
                <div className='col-12 row mx-0 py-2'>
                    <div className='w-75 py-2 row mx-0 px-0'>
                        <h1 className='my-0 fs-xxl fw-bold text-primary w-100 px-0 align-items-center d-flex'>
                            {t('LABEL_LIST_IMAGE_RESTAURANT')}
                        </h1>
                    </div>
                    {getListRestaurantImage()}
                </div>
            </div>
        </div>
    );
}
