import { ROUTER } from 'app/constants';
import { commonService } from 'app/services';
import { logoutAction } from 'app/store/authentication';
import { expandAction } from 'app/store/sidebar';
import AboutDialog from 'app/views/pages/about';
import { Menubar } from 'primereact/menubar';
import { useMemo, useRef } from 'react';
import { Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
function User() {
    const [t] = useTranslation();
    const aboutDialogRef = useRef(null);
    const navigate = useNavigate();
    const userInfo = useSelector((states) => states.authentication.userInfo);
    const isEditing = useSelector((states) => states.confirmSave.isEditing);
    const [onClickHeaderUrls, confirmLogout] = useMemo(
        () => [
            (e, path) => {
                if (!e.originalEvent.ctrlKey) {
                    e.originalEvent.preventDefault();
                    navigate(path);
                }
            },
            async () => {
                if (isEditing) {
                    const result = await commonService.modal.showConfirm({
                        header: t('LABEL_WARNING'),
                        message: t('M010'),
                        img: 'warning-message',
                        customClass: 'jig-confirm-dialog'
                    });
                    if (result) {
                        logoutAction();
                    }
                } else {
                    logoutAction();
                }
            }
        ],
        [isEditing, navigate, t]
    );
    const aboutDialog = () => {
        aboutDialogRef.current.show();
    };
    const model = useMemo(() => {
        const items = [
            {
                label: t('SIDEBAR_LABEL_USER_MANAGEMENT'),
                className: 'item-user cursor-pointer',
                show: true,
                command: () => navigate(generatePath(ROUTER.USER_MANAGEMENT))
            },
            {
                label: t('LABEL_CATEGORY'),
                className: 'item-user cursor-pointer',
                show: true,
                command: () => navigate(generatePath(ROUTER.CATEGORY))
            },
            {
                label: t('LABEL_TAG'),
                className: 'item-user cursor-pointer',
                show: true,
                command: () => navigate(generatePath(ROUTER.TAG))
            },
            {
                label: t('LABEL_UTILITIES_ROOM'),
                className: 'item-user cursor-pointer',
                show: true,
                command: () => navigate(generatePath(ROUTER.UTILITIES_ROOM))
            },
            {
                label: t('LABEL_CUSTOMER_REVIEW'),
                className: 'item-user cursor-pointer',
                show: true,
                command: () => navigate(generatePath(ROUTER.CUSTOMER_REVIEW))
            },
            {
                label: 'About',
                className: 'item-user cursor-pointer',
                show: true,
                command: aboutDialog
            },
            {
                label: 'Logout',
                className: 'item-user cursor-pointer',
                show: true,
                command: confirmLogout
            }
        ];
        return [
            {
                label: userInfo?.name,
                className: 'fs-md',
                icon: 'icon user-login',
                items: items.filter((v) => v.show)
            }
        ];
    }, [confirmLogout, onClickHeaderUrls, t, userInfo?.name]);
    return (
        <div>
            <AboutDialog ref={aboutDialogRef} />
            <Menubar className='ps-4 header-user border-0' model={model} />
        </div>
    );
}
export default function Header() {
    const toggleSidebar = useSelector((states) => states.sidebar.toggleSidebar);
    const dispatch = useDispatch();
    return (
        <>
            <Navbar collapseOnSelect expand='lg' bg='primary' className='header'>
                <div className='toggle'>
                    <label className='cursor-pointer mb-0 h-100' onClick={() => dispatch(expandAction(!toggleSidebar))}>
                        <span className='icon menu fs-2em' />
                    </label>
                </div>
                <Navbar.Toggle aria-controls='responsive-navbar-nav' className='border'>
                    <i className='pi pi-list text-white' />
                </Navbar.Toggle>
                <Navbar.Collapse id='responsive-navbar-nav' className='d-flex'>
                    <div className='col-12 d-flex justify-content-end align-items-center'>
                        <User />
                    </div>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}
