import { ROUTER } from 'app/constants';
import classNames from 'classnames';
import { PanelMenu } from 'primereact/panelmenu';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath, Link, useNavigate } from 'react-router-dom';

const renderTemplateItem = (item, options) => {
    return (
        <Link className={`item-menu d-flex align-items-center py-1 ${options.className} ${item.className}`} onClick={options.onClick}>
            <div className={`w-100 d-flex align-items-center justify-content-between menu-label text-truncate ${options.labelClassName}`}>
                <div className='sub-menu py-2 fs-lg text-white text-sidebar'> {item.label}</div>
            </div>
        </Link>
    );
};
const RenderSidebarTemplate = function () {
    const [t] = useTranslation();
    // const permission = useSelector((states) => states.authentication.userInfo.permission);
    // const { pathname } = useLocation();
    const navigate = useNavigate();
    // const onToggleSidebarItem = (event) => {
    //     setToggleSidebarItem((prev) => ({ ...prev, [event.item.id]: !prev[event.item.id] }));
    // };
    // const checkToggleSidebarItem = (id) => {
    //     if (collapseAll) {
    //         return false;
    //     }

    //     return toggleSidebarItem[id];
    // };
    /**
     * @type {import('primereact').MenuItem[]}
     */
    const list = [
        {
            id: 'room',
            key: 'room',
            // className: `${isAudit ? 'label-active' : 'label-normal'}`,
            label: t('LABEL_ROOM'),
            link: ROUTER.ROOM,
            command: () => navigate(generatePath(ROUTER.ROOM)),
            template: renderTemplateItem
        },
        {
            id: 'booking',
            key: 'booking',
            // className: `${isAudit ? 'label-active' : 'label-normal'}`,
            label: t('LABEL_BOOKING'),
            link: ROUTER.BOOKING,
            command: () => navigate(generatePath(ROUTER.BOOKING)),
            template: renderTemplateItem
        },
        {
            id: 'carousel',
            key: 'carousel',
            // className: `${isAudit ? 'label-active' : 'label-normal'}`,
            label: 'Carousel',
            link: ROUTER.CAROUSEL,
            command: () => navigate(generatePath(ROUTER.CAROUSEL)),
            template: renderTemplateItem
        },
        {
            id: 'gallery',
            key: 'gallery',
            // className: `${isAudit ? 'label-active' : 'label-normal'}`,
            label: t('LABEL_GALLERY'),
            link: ROUTER.GALLERY,
            command: () => navigate(generatePath(ROUTER.GALLERY)),
            template: renderTemplateItem
        },
        {
            id: 'contact',
            key: 'contact',
            // className: `${isAudit ? 'label-active' : 'label-normal'}`,
            label: t('LABEL_CONTACT'),
            link: ROUTER.CONTACT,
            command: () => navigate(generatePath(ROUTER.CONTACT)),
            template: renderTemplateItem
        },
        {
            id: 'service',
            // className: `${isData.includes(true) ? 'label-active' : 'label-normal'}`,
            label: 'Service',
            items: [
                {
                    // className: `p-menuitem-text sidebar__label ${isData[0] ? 'active-child' : 'not-active-child'}`,
                    label: 'Gym & Fitness',
                    icon: 'pi pi-circle ps-2',
                    classLink: 'submenu-pad-3',
                    command: () => navigate(generatePath(ROUTER.GYM)),
                    template: renderTemplateItem
                },
                {
                    // className: `p-menuitem-text sidebar__label ${isData[1] ? 'active-child' : 'not-active-child'}`,
                    label: 'Swimming Pool',
                    icon: 'pi pi-circle ps-2',
                    classLink: 'submenu-pad-4',
                    command: () => navigate(generatePath(ROUTER.SWIMMING)),
                    template: renderTemplateItem
                },
                {
                    // className: `p-menuitem-text sidebar__label ${isData[2] ? 'active-child' : 'not-active-child'}`,
                    label: 'Spa & Massage',
                    icon: 'pi pi-circle ps-2',
                    classLink: 'submenu-pad-4',
                    command: () => navigate(generatePath(ROUTER.SPA)),
                    template: renderTemplateItem
                }
            ],
            subMenu: true,
            key: ROUTER.GYM,
            expanded: true,
            // command: onToggleSidebarItem,
            template: renderTemplateItem
        },
        {
            id: 'dinning',
            // className: `${isData.includes(true) ? 'label-active' : 'label-normal'}`,
            label: 'Dinning',
            items: [
                {
                    // className: `p-menuitem-text sidebar__label ${isData[0] ? 'active-child' : 'not-active-child'}`,
                    label: 'Restaurant',
                    icon: 'pi pi-circle ps-2',
                    classLink: 'submenu-pad-3',
                    command: () => navigate(generatePath(ROUTER.RESTAURANT)),
                    template: renderTemplateItem
                },
                {
                    // className: `p-menuitem-text sidebar__label ${isData[1] ? 'active-child' : 'not-active-child'}`,
                    label: 'Cabanon Restaurant',
                    icon: 'pi pi-circle ps-2',
                    classLink: 'submenu-pad-4',
                    command: () => navigate(generatePath(ROUTER.CABANON_RESTAURANT)),
                    template: renderTemplateItem
                }
                // {
                //     // className: `p-menuitem-text sidebar__label ${isData[2] ? 'active-child' : 'not-active-child'}`,
                //     label: 'Coffee',
                //     icon: 'pi pi-circle ps-2',
                //     classLink: 'submenu-pad-4',
                //     command: () => navigate(generatePath(ROUTER.COFFEE)),
                //     template: renderTemplateItem
                // }
            ],
            // icon: `icon ${isData.includes(true) ? 'data-management-active' : 'data-management'} me-2`,
            subMenu: true,
            key: ROUTER.RESTAURANT,
            expanded: true,
            // command: onToggleSidebarItem,
            template: renderTemplateItem
        },
        {
            id: 'offers',
            key: 'offers',
            // className: `${isAudit ? 'label-active' : 'label-normal'}`,
            label: 'Special Offers',
            link: ROUTER.OFFERS,
            command: () => navigate(generatePath(ROUTER.OFFERS)),
            template: renderTemplateItem
        },
        {
            id: 'blog',
            key: 'blog',
            // className: `${isAudit ? 'label-active' : 'label-normal'}`,
            label: t('LABEL_BLOGS'),
            link: ROUTER.BLOG,
            command: () => navigate(generatePath(ROUTER.BLOG)),
            template: renderTemplateItem
        }
    ];
    return list;
};
export default function SideBar() {
    const toggleSidebar = useSelector((states) => states.sidebar.toggleSidebar);
    const [toggleSidebarItem, setToggleSidebarItem] = useState({
        category: true,
        'data-management': true,
        'department-management': true
    });

    const styleClass = classNames(' sidebar d-flex flex-column', {
        'sidebar-collapse': toggleSidebar
    });
    const items = RenderSidebarTemplate(toggleSidebar, toggleSidebarItem, setToggleSidebarItem);

    return (
        <div className={styleClass}>
            <div className='mb-3'>
                <div className={toggleSidebar ? 'logo-toggle' : 'logo'} />
                <div className={toggleSidebar ? 'd-none' : 'w-100 d-flex justify-content-center'}>
                    <span className='text-white text-wrap text-center logo-caption' style={{ width: '15rem' }}>
                        Cordial Hotel
                    </span>
                </div>
            </div>
            <div className='fill-height'>
                <div className='h-100'>
                    <PanelMenu multiple model={items} className='overflowY-auto h-100' />
                </div>
            </div>
        </div>
    );
}
