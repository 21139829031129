import { BLOG_RECENT_URL, BLOG_URL, BLOG_URL_DETAIL } from 'app/constants';
import { requestDelete, requestGet, requestPost, requestPut } from 'app/utils/request';
import { HttpStatusCode } from 'axios';
/**
 * Handle get list blog
 * @param {Object} value search content
 */
const search = async (value) => {
    // init params
    const { searchField, selectedCategory } = value;
    const params = {
        searchField,
        Category: selectedCategory
    };
    const res = await requestGet(BLOG_URL, { params }); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return data.payload;
    } else {
        return {
            data: []
        };
    }
};
const details = async (params) => {
    const res = await requestGet(BLOG_URL_DETAIL, { params }); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return data.payload;
    } else {
        return {
            data: []
        };
    }
};
/* Request to create Blog
 * @param {Object} data store user management data
 */
const create = (data) => requestPost(BLOG_URL, data);
/* Request to update info Blog
 * @param {Object} data store user management data
 */
const update = (data) => requestPut(BLOG_URL, data);
/* Request to remove Blog
 * @param {Object} data store user management data
 */
const remove = (data) => requestDelete(BLOG_URL, { data });

const detailBlog = async (params) => {
    const res = await requestGet(BLOG_URL_DETAIL, { params }); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return data.payload;
    } else {
        return {
            data: []
        };
    }
};
const recentBlog = async () => {
    const res = await requestGet(BLOG_RECENT_URL); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return data.payload;
    } else {
        return {
            data: {
                recent: [],
                categories: [],
                tags: []
            }
        };
    }
};
export default { search, create, update, remove, details, detailBlog, recentBlog };
