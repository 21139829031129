export const LOGIN_URL = '/api/uaa/authentication/login';
export const VERIFY_URL = '/api/uaa/authentication/verify';
export const LOGOUT_URL = '/api/uaa/authentication/logout';
//#region user management
export const USER_MANAGEMENT = '/api/user-management';
export const USER_MANAGEMENT_VERIFY_ACCOUNT_NAME = '/api/user-management/verify';
//#endregion
export const CUSTOMER_REVIEW_URL = '/api/customer-review';
//#region category
export const CATEGORY_URL = '/api/category';
export const CATEGORY_VERIFY_NAME = '/api/category/verify';
//#endregion
//#region tag
export const TAG_URL = '/api/tag';
export const TAG_VERIFY_NAME = '/api/tag/verify';
//#endregion
export const CONTACT_URL = '/api/contact';
export const ABOUT_URL = '/api/about';
export const ROOM_URL = '/api/room';
export const ROOM_DETAIL_URL = '/api/room/detail';
export const ROOM_VERIFY_NAME = 'api/room/verify';
export const UTILITIES_URL = '/api/room/utilities';
export const CAROUSEL_URL = '/api/carousel';
export const UTILITIES_VERIFY = '/api/room/utilities/verify';
export const FM_URL = '/api/file-manager';
export const FM_UPLOAD = '/api/file-manager/upload';
export const ROOM_REMOVE_IMAGE_URL = '/api/room/remove-image';
export const SERVICE_REMOVE_IMAGE_URL = '/api/service/remove-image';
export const SERVICE_URL = '/api/service';
export const GALLERY_REMOVE_IMAGE_URL = '/api/gallery/remove-image';
export const GALLERY_URL = '/api/gallery';
export const SERVICE_DETAIL_URL = '/api/service/detail';
//#region blog
export const BLOG_URL = '/api/blogs';
export const BLOG_URL_DETAIL = '/api/blogs/detail';
export const BLOG_RECENT_URL = '/api/blogs/recent';
//#endregion
export const OFFERS_URL = '/api/offers';
export const IGNORE_AUTHOR = [LOGIN_URL, VERIFY_URL, LOGOUT_URL];
export const BOOKING_URL = '/api/booking';
export const BOOKING_DETAIL_URL = '/api/booking/detail';
export const BOOKING_CONFIRM_URL = '/api/booking/confirm';
