/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ROUTER, VARCHAR_MAX_LENGTH, VARCHAR_MIN_LENGTH } from 'app/constants';
import { blogService, categoryService, commonService, tagService } from 'app/services';
import validatorChain from 'app/utils/validator-chain';
import { HttpStatusCode } from 'axios';
import { t } from 'i18next';
import _ from 'lodash';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
export default function AddBlog() {
    const location = useLocation();
    const [Title, setTitle] = useState('');
    const [selectedTag, setTag] = useState([]);
    const [SpecialContent, setSpecialContent] = useState('');
    const [Content, setContent] = useState('');
    const [Image, setImage] = useState('');
    const [optionCategory, setOptionCategory] = useState([]);
    const [optionTag, setOptionTag] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();
    const [error, setError] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        commonService.preloader.hide();
        fetchData();
    }, []);
    /**
     * Validate data
     * @returns Boolean
     */
    const validateData = () => {
        const errors = {
            Title: validatorChain(Title)
                .isRequire('M001')
                .isLength({ min: VARCHAR_MIN_LENGTH, max: VARCHAR_MAX_LENGTH }, 'M003')
                .getError(),
            selectedTag: validatorChain(selectedTag).isRequire('M001').getError(),
            selectedCategory: validatorChain(selectedCategory).isRequire('M001').getError(),
            SpecialContent: validatorChain(SpecialContent).isRequire('M001').getError(),
            Content: validatorChain(Content).isRequire('M001').getError()
        };
        setError(errors);
        return Object.values(errors).reduce((r, c) => (c === null ? r : false), true);
    };
    /**
     * fetch data from db with ID
     * @returns
     */
    const fetchData = async () => {
        try {
            commonService.preloader.show();
            const data = await blogService.details({ ID: location.state });
            if (_.isNull(data)) {
                return false;
            }
            setTitle(data.Title);
            setSpecialContent(data.SpecialContent);
            setContent(data.Content);
            setSelectedCategory(parseInt(data.Category, 10));
            setTag(data.Tag.map((v) => parseInt(v, 10)));
            setImage(data.Image);
            return true;
        } catch (error) {
            return false;
        } finally {
            commonService.preloader.hide();
        }
    };
    /**
     * edit blog
     */
    // eslint-disable-next-line no-unused-vars
    const edit = async () => {
        if (validateData()) {
            const res = await blogService.update({
                ID: location.state,
                Title,
                Content,
                Images: Image || '',
                Category: selectedCategory,
                Tag: selectedTag,
                SpecialContent
            });
            if (res.status === HttpStatusCode.Ok) {
                commonService.toast.show({
                    severity: 'success',
                    summary: t('INFO_MESSAGE'),
                    detail: t('M012')
                });
                fetchData();
            } else {
                commonService.toast.show({
                    severity: 'error',
                    summary: t('ERROR_MESSAGE'),
                    detail: t('M013')
                });
            }
        }
    };
    const getCategory = async () => {
        const data = await categoryService.search({ searchField: '', sortField: 'UpdatedAt', sortOrder: 1, first: 0, rows: 1000 });
        setOptionCategory(data.data);
    };
    const getTag = async () => {
        const data = await tagService.search({ searchField: '', sortField: 'UpdatedAt', sortOrder: 1, first: 0, rows: 1000 });
        setOptionTag(data.data);
    };
    /**
     * Fetch first render data
     */
    useEffect(() => {
        commonService.preloader.hide();
        getCategory();
        getTag();
    }, []);
    return (
        <div>
            <div className='auto-container row container-rooms pb-4'>
                <div className='col-12'>
                    <h2 className='fw-bold'>{t('LABEL_BLOG_EDIT')}</h2>
                    <div className='row'>
                        <div className='col-md-4 py-2'>
                            <label htmlFor='title'>{t('LABEL_BLOG_TITLE')}</label>
                            <InputText value={Title} onChange={(e) => setTitle(e.target.value)} className='w-100' id='title' />
                        </div>
                        <div className='col-md-4 py-2'>
                            <label htmlFor='Category'>{t('LABEL_CATEGORY')}</label>
                            <Dropdown
                                className='w-100 fs-md ms-3 multi-select-systems'
                                value={selectedCategory}
                                options={optionCategory}
                                optionLabel='Name'
                                optionValue='ID'
                                onChange={(e) => {
                                    setSelectedCategory(e.value);
                                }}
                            />
                        </div>
                        <div className='col-md-4 py-2'>
                            <label htmlFor='Tag'>{t('LABEL_TAG')}</label>
                            <MultiSelect
                                value={selectedTag}
                                onChange={(e) => setTag(e.value)}
                                options={optionTag}
                                optionLabel='Name'
                                optionValue='ID'
                                maxSelectedLabels={3}
                                id='disability'
                                className='w-100'
                            />
                        </div>
                        <div className='col-md-4 py-2'>
                            <label className='break-work text-danger'>{t(error['Title'])}</label>
                        </div>
                        <div className='col-md-4 py-2'>
                            <label className='break-work text-danger'>{t(error['selectedCategory'])}</label>
                        </div>
                        <div className='col-md-4 py-2'>
                            <label className='break-work text-danger'>{t(error['selectedTag'])}</label>
                        </div>
                        <div className='col-md-12 py-2'>
                            <label htmlFor='specialcontent'>{t('LABEL_SPECIAL_CONTENT')}</label>
                            <InputTextarea
                                value={SpecialContent}
                                onChange={(e) => setSpecialContent(e.target.value)}
                                className='w-100'
                                id='specialcontent'
                                rows={5}
                                cols={20}
                            />
                        </div>
                        <div className='col-md-12 py-2'>
                            <label className='break-work text-danger'>{t(error['SpecialContent'])}</label>
                        </div>
                        <div className='col-md-12 py-2'>
                            <label htmlFor='content'>{t('TABLE_LABEL_CONTENT')}</label>
                            <CKEditor
                                editor={ClassicEditor}
                                data={Content}
                                onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setContent(data);
                                    // console.log({ event, editor, data });
                                }}
                                onBlur={(event, editor) => {
                                    // console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    // console.log('Focus.', editor);
                                }}
                            />
                        </div>
                        <div className='col-md-12 py-2'>
                            <label className='break-work text-danger'>{t(error['Content'])}</label>
                        </div>
                        <div className='row mx-0 py-4 d-flex justify-content-center align-items-center'>
                            <Button className='btn-cancel-sm mx-2' variant='default' onClick={() => edit()}>
                                {t('LABEL_BLOG_EDIT')}
                            </Button>
                            <Button className='btn-action-sm mx-2' variant='default' onClick={() => navigate(ROUTER.BLOG)}>
                                {t('LABEL_CANCEL')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
