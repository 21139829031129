import { ROUTER } from 'app/constants';
import { t } from 'i18next';

export default function NotFound() {
    return (
        <div className='auto-container page-not-found'>
            <div className='page-content text-center'>
                <h1>404</h1>
                <h2>{t('M005')}</h2>
                <div className='text'>{t('M006')}</div>
                <a href={ROUTER.BASE_NAME} className='theme-btn btn-action-sm'>
                    {t('LABEL_GO_TO_HOMEPAGE')}
                </a>
            </div>
        </div>
    );
}
