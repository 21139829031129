/**
 * The type of login status code
 */
export const MASTER_USERNAME = ['root', 'administrator'];
export const SOCKET_CONNECT = 'connect';
export const SOCKET_CONNECT_ERROR = 'connect_error';
export const SOCKET_DISCONNECT = 'disconnect';
export const SOCKET_RECONNECT = 'reconnect';
export const SOCKET_RECONNECT_FAILED = 'reconnect_failed';
export const SOCKET_STATE = 'state';
export const SOCKET_UPDATE_ROLE = 'update_role';
export const ASYNC_TASK_EVENT = 'async-task';
export const GENERATE_COMMAND = 'generate-command';
export const NOTIFICATION_BROADCAST_MSG = 'NOTIFICATION_BROADCAST_MSG';
export const KEYBOARD_CHAR_CODE = {
    ENTER: 13,
    UPPERCASE_E: 69,
    LOWERCASE_E: 101
};
export const DATE_FORMAT_SHOW = 'YYYY/MM/DD HH:mm:ss';
export const DATE_FORMAT_ONLY = 'YYYY/MM/DD';
export const DATE_FORMAT = 'YYYY-MM-DDTHH:MM:SS';
export const CALENDAR_FORMAT = 'yy/mm/dd';
export const LIMIT_FILE_SIZE = 50 * 1024 * 1024;
export const LIMIT_DOWNLOAD = 500 * 1024 * 1024;
export const WEBSOCKET_COOKIE_NAME = 'sid';
export const ProcessStatus = {
    success: 1,
    failed: 2,
    running: 3,
    pending: 4,
    unknown: 5,
    invalid: 6,
    dontHavePermission: 7
};
export const LoginStatus = {
    success: 1,
    invalid: 2,
    notFound: 3,
    dbError: 4
};
export const FM_TYPE = {
    DIRECTORY: 0,
    FILE: 1
};

export const AsyncTaskType = {
    downloadFile: 1,
    exportFile: 2,
    saveFile: 3
};
export const SORT_ASC_DIR = 1;
export const DEFAULT_SORT_DIR = -1;
export const FIRST_PAGE = 0;
export const PER_PAGE = 30;
export const INTEGER_MAX_VALUE = 2147483647;
export const INTEGER_MIN_VALUE = 0;
export const VARCHAR_MIN_LENGTH = 0;
export const VARCHAR_MAX_LENGTH = 255;
export const VACHAR_NAME_MAX = 50;
export const FM_NAME_REGEX = /[\\/?%*:|"<>]/;
export const SELECTED_RANGER_REGEX = /^\d*-\d*$/;
export const FM_NAME_MIN = 1;
export const FM_NAME_MAX = 255;
export const ID_FIELD = 'ID';
export const DEFAULT_SORT_FIELD = 'UpdatedAt';

export const WEBSOCKET_CHANNEL = 'websocket';
export const WEBSOCKET_HEADER = 'websocket-id';
export const LANG_JP = 'ja-JP';
export const LANG_EN = 'en-US';
export const ConnectStatus = {
    Success: 0,
    Fail: 1,
    Error: 2,
    Disconnect: 3,
    Other: 4
};
export const ROLE = [
    {
        value: 1,
        label: 'Admin'
    },
    {
        value: 2,
        label: 'Manager'
    },
    {
        value: 3,
        label: 'User'
    }
];
export const enumRole = {
    admin: 1,
    manager: 2,
    user: 3
};
export const SORT_ORDER = {
    ASC: 'ASC',
    DESC: 'DESC'
};
export const DISABILITY_LIST = [
    { value: 1, label: 'Access via exterior corridors' },
    { value: 2, label: 'Accessible bath' },
    { value: 3, label: 'Closed-captioned TV' },
    { value: 4, label: 'Doorbell and phone notification' },
    { value: 5, label: 'Low-height plug sockets in bathroom' },
    { value: 6, label: 'Visual fire alarm' },
    { value: 7, label: 'Wheelchair-accessible' },
    { value: 8, label: 'Wheelchair-width doorways' },
    { value: 9, label: 'Low-height view port in door' },
    { value: 10, label: 'Area rugs in room' },
    { value: 11, label: 'Grab bar in bath' },
    { value: 12, label: 'Hand-held shower head' },
    { value: 13, label: 'Hardwood flooring in room' },
    { value: 14, label: 'Height-adjustable shower head' },
    { value: 15, label: 'Portable shower seat' },
    { value: 16, label: 'Smooth flooring in room' },
    { value: 17, label: 'Thin carpet in room' },
    { value: 18, label: 'Tile flooring in room' },
    { value: 19, label: 'Visual doorbell notification' },
    { value: 20, label: 'Low-height door lock' },
    { value: 21, label: 'Low-height view port in door' }
];
export const BATHROOM_LIST = [
    { value: 1, label: 'Dressing gowns' },
    { value: 2, label: 'Bidet' },
    { value: 3, label: 'Free toiletries' },
    { value: 4, label: 'Hairdryer' },
    { value: 5, label: 'Private bathroom' },
    { value: 6, label: 'Separate bath and shower' },
    { value: 7, label: 'Shampoo' },
    { value: 8, label: 'Slippers' },
    { value: 9, label: 'Soap' },
    { value: 10, label: 'Toilet paper' },
    { value: 11, label: 'Toothbrush and toothpaste' },
    { value: 12, label: 'Towels' },
    { value: 13, label: 'Hydromassage showerhead' },
    { value: 14, label: 'Rainfall showerhead' },
    { value: 15, label: 'Designer toiletries' },
    { value: 16, label: 'Shower/bath combination' }
];
export const BEDROOM_LIST = [
    { value: 1, label: 'Air conditioning' },
    { value: 2, label: 'Bed sheets' },
    { value: 3, label: 'Blackout curtains' },
    { value: 4, label: 'Cots/infant beds (surcharge)' },
    { value: 5, label: 'Egyptian cotton bed sheets' },
    { value: 6, label: 'Pillow menu' },
    { value: 7, label: 'Premium bedding' },
    { value: 8, label: 'Rollaway/extra beds (surcharge)' },
    { value: 9, label: 'Select Comfort bed' },
    { value: 10, label: 'Separate bedroom' },
    { value: 11, label: 'Free cots/infant beds' },
    { value: 12, label: 'Hypo-allergenic bedding' },
    { value: 13, label: 'No rollaway/extra beds available' },
    { value: 14, label: 'Rollaway/extra beds (surcharge)' },
    { value: 15, label: 'No cots (infant beds) available' },
    { value: 16, label: 'Day bed' }
];
export const ENTERTAINMENT_LIST = [
    { value: 1, label: '40-inch Smart TV' },
    { value: 2, label: '42-inch Smart TV' },
    { value: 3, label: 'Cable channels' },
    { value: 4, label: 'Premium TV channels' },
    { value: 5, label: 'Satellite channels' }
];
export const INTERNET_LIST = [
    { value: 1, label: 'Free WiFi (100+ Mbps (good for 1–2 people or up to 6 devices))' },
    { value: 2, label: 'Free Wifi' }
];
export const FAMILY_LIST = [
    { value: 1, label: 'Baby bath' },
    { value: 2, label: 'Changing table' },
    { value: 3, label: 'children&apos;s books' },
    { value: 4, label: 'High chair' },
    { value: 5, label: 'Musical instruments' },
    { value: 6, label: 'Playpen' }
];
export const FOOD_LIST = [
    { value: 1, label: 'Champagne service' },
    { value: 2, label: 'Coffee/tea maker' },
    { value: 3, label: 'Electric kettle' },
    { value: 4, label: 'Free bottled water' },
    { value: 5, label: 'Minibar' },
    { value: 6, label: 'Paper towels' },
    { value: 7, label: 'Room service (limited)' },
    { value: 8, label: 'Mini fridge' },
    { value: 9, label: 'Cookware/dishes/utensils' },
    { value: 10, label: 'Dining area' },
    { value: 11, label: 'Microwave' },
    { value: 12, label: 'Free tea bags/instant coffee' },
    { value: 13, label: 'Kitchenette' },
    { value: 14, label: 'Stovetop' },
    { value: 15, label: 'Fridge' }
];
export const MORE_LIST = [
    { value: 1, label: 'Connecting rooms available' },
    { value: 2, label: 'Daily housekeeping' },
    { value: 3, label: 'Desk' },
    { value: 4, label: 'Fireplace' },
    { value: 5, label: 'Laptop workspace' },
    { value: 6, label: 'Phone' },
    { value: 7, label: 'Safe' },
    { value: 8, label: 'Soundproofed rooms' },
    { value: 9, label: 'Individually decorated' },
    { value: 10, label: 'Individually furnished' },
    { value: 11, label: 'View – mountain' },
    { value: 12, label: 'Wardrobe' },
    { value: 13, label: 'Shared accommodation' },
    { value: 14, label: '1 living room' },
    { value: 15, label: 'Located on the top floor' },
    { value: 16, label: 'Sitting area' },
    { value: 17, label: 'View – city' },
    { value: 18, label: 'Desk chair' },
    { value: 19, label: 'Dinning Table' },
    { value: 20, label: 'Fireplace' },
    { value: 21, label: 'View – ocean' },
    { value: 22, label: 'Views – city, partial sea' }
];
export const HOTEL = [
    { value: 1, label: 'Cordial Hotel' },
    { value: 2, label: 'Cordial Grand Hotel' }
];
export const GROUP = {
    SERVICE: 1,
    DINNING: 2,
    ROOM: 3,
    GALLERY: 4,
    BLOG: 5,
    CAROUSEL: 6
};
export const SERVICE_TYPE = {
    GYM: 1,
    SWIMMING: 2,
    SPA: 3
};
export const DINNING_TYPE = {
    RESTAURANT: 1,
    CABANON_RESTAURANT: 2,
    COFFEE: 3
};
export const ENUM_GALLERY = {
    ROOM: 1,
    SPA: 2,
    RESTAURANT: 3,
    SWIMMING: 4,
    GYM: 5
};
export const ENUM_BLOG = {
    TOUR: 1,
    NEW: 2
};
export const BLOG = [
    { value: ENUM_BLOG.TOUR, label: 'Tour' },
    { value: ENUM_BLOG.NEW, label: 'New' }
];
export const UTILITIES_ROOM = {
    BATHROOM: 1,
    BEDROOM: 2,
    DISABILITY: 3,
    ENTERTAINMENT: 4,
    FAMILY: 5,
    FOOD: 6,
    INTERNET: 7,
    MORE: 8
};
export const UTILITIES = [
    { value: UTILITIES_ROOM.BATHROOM, label: 'Bathroom' },
    { value: UTILITIES_ROOM.BEDROOM, label: 'Bedroom' },
    { value: UTILITIES_ROOM.DISABILITY, label: 'Disability' },
    { value: UTILITIES_ROOM.ENTERTAINMENT, label: 'Entertainment' },
    { value: UTILITIES_ROOM.FAMILY, label: 'Family' },
    { value: UTILITIES_ROOM.FOOD, label: 'Food and Drink' },
    { value: UTILITIES_ROOM.INTERNET, label: 'Internet' },
    { value: UTILITIES_ROOM.MORE, label: 'More' }
];
export const IMAGE_TYPE = {
    CAROUSEL: 1,
    RESTAURANT: 2,
    CABANON_RESTAURANT: 3
};
export const ACTION_MODE = {
    ADD: 1,
    EDIT: 2,
    DETAIL: 3
};
export const BOOKING_STATUS = {
    CANCEL: 1,
    CONFIRM: 2,
    WAITING: 3
};
