/* eslint-disable no-undef */
//#region  import
// page
import { ROUTER } from 'app/constants';
import { authService, commonService } from 'app/services';
import { loginAction, verifyAction } from 'app/store/authentication';
import { LoaderDataError, MasterLayout, Modal, Preloader, PrivateRoute } from 'app/views/core';
// core
import {
    AddBlog,
    AddRoom,
    Blog,
    BlogDetail,
    BookingManagement,
    CabanonRestaurant,
    Carousel,
    CategoryManagement,
    Contact,
    CustomerReviewManagement,
    EditBlog,
    EditRoom,
    GalleryManagement,
    Gym,
    ImageHotel,
    Login,
    NotFound,
    Offers,
    Restaurant,
    RoomDetail,
    RoomManagement,
    Spa,
    Swimming,
    TagManagement,
    UserManagement,
    UtilitiesRoom
} from 'app/views/pages';
import { HttpStatusCode } from 'axios';
// environment
import env from 'environments';
import { Messages } from 'primereact/messages';
import { Toast } from 'primereact/toast';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';

//#end region

export default function App() {
    const dispatch = useDispatch();
    useEffect(() => {
        const verifyAuthen = async () => {
            try {
                const res = await authService.checkAuth();
                if (res.status === HttpStatusCode.Ok) {
                    dispatch(loginAction(res.data.payload));
                }
            } catch (error) {
                console.log(error);
            } finally {
                dispatch(verifyAction());
                commonService.preloader.hide();
            }
        };
        verifyAuthen();
    }, []);

    const router = useMemo(
        () =>
            createBrowserRouter(
                createRoutesFromElements(
                    <Route errorElement={LoaderDataError}>
                        <Route element={<PrivateRoute />}>
                            <Route element={<MasterLayout withoutSideBar withoutHeader />}>
                                <Route path={ROUTER.LOGIN} element={<Login />} />
                            </Route>
                            <Route element={<MasterLayout />}>
                                <Route path={ROUTER.BASE_NAME} key={ROUTER.BASE_NAME} element={<UserManagement />} withoutSideBar />
                                <Route
                                    path={ROUTER.USER_MANAGEMENT}
                                    key={ROUTER.USER_MANAGEMENT}
                                    element={<UserManagement />}
                                    withoutSideBar
                                />
                                <Route path={ROUTER.BOOKING} key={ROUTER.BOOKING} element={<BookingManagement />} />
                                <Route path={ROUTER.CATEGORY} key={ROUTER.CATEGORY} element={<CategoryManagement />} />
                                <Route path={ROUTER.CUSTOMER_REVIEW} key={ROUTER.CUSTOMER_REVIEW} element={<CustomerReviewManagement />} />
                                <Route path={ROUTER.TAG} key={ROUTER.TAG} element={<TagManagement />} />
                                <Route path={ROUTER.CONTACT} key={ROUTER.CONTACT} element={<Contact />} />
                                <Route path={ROUTER.ROOM} key={ROUTER.ROOM} element={<RoomManagement />} />
                                <Route path={ROUTER.CAROUSEL} key={ROUTER.CAROUSEL} element={<Carousel />} />
                                <Route path={ROUTER.BLOG} key={ROUTER.BLOG} element={<Blog />} />
                                <Route path={ROUTER.ADD_BLOG} key={ROUTER.ADD_BLOG} element={<AddBlog />} />
                                <Route path={ROUTER.EDIT_BLOG} key={ROUTER.EDIT_BLOG} element={<EditBlog />} />
                                <Route path={ROUTER.ROOM_DETAIL} element={<RoomDetail />} />
                                <Route path={ROUTER.BLOG_DETAIL} element={<BlogDetail />} />
                                <Route path={ROUTER.EDIT_ROOM} element={<EditRoom />} />
                                <Route path={ROUTER.ADD_ROOM} element={<AddRoom />} />
                                <Route path={ROUTER.ROOM_IMAGE} element={<ImageHotel />} />
                                <Route path={ROUTER.SPA} element={<Spa />} />
                                <Route path={ROUTER.SWIMMING} element={<Swimming />} />
                                <Route path={ROUTER.GYM} element={<Gym />} />
                                <Route path={ROUTER.RESTAURANT} element={<Restaurant />} />
                                <Route path={ROUTER.UTILITIES_ROOM} element={<UtilitiesRoom />} />
                                <Route path={ROUTER.CABANON_RESTAURANT} element={<CabanonRestaurant />} />
                                <Route path={ROUTER.GALLERY} key={ROUTER.GALLERY} element={<GalleryManagement />} />
                                <Route path={ROUTER.OFFERS} key={ROUTER.OFFERS} element={<Offers />} />
                            </Route>
                        </Route>
                        <Route path='*' element={<NotFound />} />
                    </Route>
                ),
                {
                    basename: env.BASE_NAME
                }
            ),
        []
    );
    return (
        <>
            <RouterProvider router={router} />
            <Modal ref={(el) => (global.modal = el)} backdrop='static' />
            <Toast ref={(el) => (global.toast = el)} position='bottom-right' baseZIndex={1060} />
            <Messages
                ref={(el) => (global.messages = el)}
                className='position-absolute w-100 z-index-5 msg-notify d-none'
                style={{ top: 0 }}
            />
            <Preloader />
        </>
    );
}
