import { ENUM_GALLERY, GROUP } from 'app/constants';
import { commonService, fileManagerService } from 'app/services';
import galleryService from 'app/services/gallery/gallery.service';
import { t } from 'app/translations';
import { HttpStatusCode } from 'axios';
import { Image } from 'primereact/image';
import { useEffect, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
export default function RoomGallery() {
    const [image, setData] = useState([]);
    /**
     * fetch data from db with ID
     * @returns
     */
    const fetchData = async () => {
        commonService.preloader.show();
        const data = await galleryService.get({ category: ENUM_GALLERY.ROOM });
        setData(data.ImageList);
        commonService.preloader.hide();
        return true;
    };
    const onUpload = () => {
        const file = document.createElement('INPUT');
        file.setAttribute('type', 'file');
        file.accept = 'image/x-png, image/gif, image/jpeg, image/webp';
        file.setAttribute('multiple', true);
        file.onchange = handleUpload;
        file.click();
    };
    const handleUpload = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const files = event.target.files || event.dataTransfer.files;
        for await (const file of files) {
            const data = new FormData();
            data.append('Category', ENUM_GALLERY.ROOM);
            data.append('Type', GROUP.GALLERY);
            data.append('files', file);
            await fileManagerService.upload(data);
        }
        commonService.toast.show({
            severity: 'success',
            summary: t('INFO_MESSAGE'),
            detail: t('M007')
        });
        fetchData();
    };
    /**
     * Handle Delete Action
     * @param {Number} ID ID action
     */
    const onRemove = async (file) => {
        const result = await commonService.modal.showConfirm({
            header: t('LABEL_DELETE_ITEM'),
            message: t('M016'),
            img: 'delete-message',
            customClass: 'warning-confirm-delete px-0'
        });
        if (result) {
            commonService.preloader.show();
            const res = await galleryService.removeImage({ type: ENUM_GALLERY.ROOM, file });
            if (res.status === HttpStatusCode.Ok) {
                commonService.toast.show({
                    severity: 'success',
                    summary: t('INFO_MESSAGE'),
                    detail: t('M018')
                });
                fetchData();
                commonService.preloader.hide();
                return true;
            }
            commonService.preloader.hide();
            return commonService.toast.show({
                severity: 'error',
                summary: t('ERROR_MESSAGE'),
                detail: t('M017')
            });
        }
    };
    useEffect(() => {
        commonService.preloader.hide();
        fetchData();
    }, []);
    const renderImage = () => {
        const page = image.length % 4 === 0 ? Math.floor(image.length / 4) : Math.floor(image.length / 4) + 1;
        const list = [];
        for (let i = 0; i < page; i++) {
            if (i % 2 === 0) {
                const item = (
                    <Row className='m-0 gallery-list switch-right col-md-12 col-sm-12 h-100'>
                        <div className={i * 4 < image.length ? 'col-md-6 col-sm-12 px-0' : 'd-none'}>
                            <div className='img-right'>
                                <Image
                                    src={`${process.env.PUBLIC_URL}/image/gallery/1/${image[i * 4]}`}
                                    alt='Image'
                                    preview
                                    className='img-min-screen'
                                />
                                <Button
                                    key='Upload'
                                    variant='secondary'
                                    onClick={() => onRemove(image[i * 4])}
                                    className='delete-gallery btn-action-sm'>
                                    Delete
                                </Button>
                            </div>
                        </div>
                        <div className={i * 4 + 1 < image.length ? 'col-md-6 col-sm-12 px-0 h-100' : 'd-none'}>
                            <div className='img-left'>
                                <Row className='m-0 gallery-height'>
                                    <div className='col-md-6 col-sm-12 px-0 h-100'>
                                        <div className='img-gs'>
                                            <Image
                                                src={`${process.env.PUBLIC_URL}/image/gallery/1/${image[i * 4 + 1]}`}
                                                alt='Image'
                                                preview
                                                className='img-min-screen'
                                            />
                                            <Button
                                                key='Upload'
                                                variant='secondary'
                                                onClick={() => onRemove(image[i * 4 + 1])}
                                                className='delete-gallery btn-action-sm'>
                                                Delete
                                            </Button>
                                        </div>
                                    </div>
                                    <div className={i * 4 + 2 < image.length ? 'col-md-6 col-sm-12 px-0 h-100' : 'd-none'}>
                                        <div className='img-ge'>
                                            <Image
                                                src={`${process.env.PUBLIC_URL}/image/gallery/1/${image[i * 4 + 2]}`}
                                                alt='Image'
                                                preview
                                                className='img-min-screen'
                                            />
                                            <Button
                                                key='Upload'
                                                variant='secondary'
                                                onClick={() => onRemove(image[i * 4 + 2])}
                                                className='delete-gallery btn-action-sm'>
                                                Delete
                                            </Button>
                                        </div>
                                    </div>
                                </Row>
                                <Row className='m-0 gallery-height'>
                                    <div className={i * 4 + 3 < image.length ? 'col-md-12 col-sm-12 px-0 h-100' : 'd-none'}>
                                        <div className='img-bottom'>
                                            <Image
                                                src={`${process.env.PUBLIC_URL}/image/gallery/1/${image[i * 4 + 3]}`}
                                                alt='Image'
                                                preview
                                                className='img-min-screen'
                                            />
                                            <Button
                                                key='Upload'
                                                variant='secondary'
                                                onClick={() => onRemove(image[i * 4 + 3])}
                                                className='delete-gallery btn-action-sm'>
                                                Delete
                                            </Button>
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </Row>
                );
                list.push(item);
            } else {
                const value = (
                    <Row className='m-0 gallery-list my-2 switch-left col-sm-12 h-100'>
                        <div className={i * 4 + 3 < image.length ? 'col-md-6 col-sm-12 px-0 img-big' : 'd-none'}>
                            <div className='img-left'>
                                <Image
                                    src={`${process.env.PUBLIC_URL}/image/gallery/1/${image[i * 4 + 3]}`}
                                    alt='Image'
                                    preview
                                    className='img-min-screen'
                                />
                                <Button
                                    key='Upload'
                                    variant='secondary'
                                    onClick={() => onRemove(image[i * 4 + 3])}
                                    className='btn-action-sm delete-gallery'>
                                    Delete
                                </Button>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-12 px-0 h-100'>
                            <div className='img-group'>
                                <Row className='m-0 gallery-height'>
                                    <div className={i * 4 + 1 < image.length ? 'col-md-6 col-sm-12 px-0 h-100' : 'd-none'}>
                                        <div className='img-right'>
                                            <Image
                                                src={`${process.env.PUBLIC_URL}/image/gallery/1/${image[i * 4 + 1]}`}
                                                alt='Image'
                                                preview
                                                className='img-min-screen'
                                            />
                                            <Button
                                                key='Upload'
                                                variant='secondary'
                                                onClick={() => onRemove(image[i * 4 + 1])}
                                                className='delete-gallery btn-action-sm'>
                                                Delete
                                            </Button>
                                        </div>
                                    </div>
                                    <div className={i * 4 < image.length ? 'col-md-6 col-sm-12 px-0 h-100' : 'd-none'}>
                                        <div className='image-about h-100'>
                                            <Image
                                                src={`${process.env.PUBLIC_URL}/image/gallery/1/${image[i * 4]}`}
                                                alt='Image'
                                                preview
                                                className='img-min-screen'
                                            />
                                            <Button
                                                key='Upload'
                                                variant='secondary'
                                                onClick={() => onRemove(image[i * 4])}
                                                className='delete-gallery btn-action-sm'>
                                                Delete
                                            </Button>
                                        </div>
                                    </div>
                                </Row>
                                <Row className='m-0 gallery-height'>
                                    <div className={i * 4 + 2 < image.length ? 'col-md-12 col-sm-12 px-0 h-100' : 'd-none'}>
                                        <div className='img-bottom'>
                                            <Image
                                                src={`${process.env.PUBLIC_URL}/image/gallery/1/${image[i * 4 + 2]}`}
                                                alt='Image'
                                                preview
                                                className='img-min-screen'
                                            />
                                            <Button
                                                key='Upload'
                                                variant='secondary'
                                                onClick={() => onRemove(image[i * 4 + 2])}
                                                className='delete-gallery btn-action-sm'>
                                                Delete
                                            </Button>
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </Row>
                );
                list.push(value);
            }
        }
        return list;
    };
    return (
        <div>
            <div className='col-12 row mx-0 py-2'>
                <div className='w-75 py-2 row mx-0 px-0'>
                    <h1 className='my-0 fs-xxl fw-bold text-primary w-100 px-0 align-items-center d-flex'>Room & Suilt</h1>
                </div>
                <div className='w-25 px-0 d-flex justify-content-end'>
                    <Button key='Upload' variant='secondary' onClick={() => onUpload()} className='btn-action-sm'>
                        Add Image
                    </Button>
                </div>
            </div>
            {renderImage()}
        </div>
    );
}
