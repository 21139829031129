import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Login } from '../pages';

import { ROUTER } from 'app/constants';
import { commonService } from 'app/services';

const closeGlobalModal = () => {
    commonService.modal.onClose();
};
export default function PrivateRoute() {
    const location = useLocation();
    const authenticated = useSelector((states) => states.authentication.authenticated);
    const verify = useSelector((states) => states.authentication.verify);
    const navigate = useNavigate();
    // const userInfo = useSelector((states) => states.authenticated.userInfo);
    useEffect(closeGlobalModal, [location]);
    if (verify) {
        const isLoginPage = location.pathname === ROUTER.LOGIN;
        if (authenticated) {
            // const url = new URLSearchParams(location.search).get('url');
            // if(url)
            // {
            //     // window.location.href = generateURL()
            // }
            if (isLoginPage) {
                navigate(ROUTER.BASE_NAME);
            }
            return <Outlet />;
        }
        return <Login />;
    }
    return null;
}
