import { CUSTOMER_REVIEW_URL, SORT_ORDER } from 'app/constants';
import { requestDelete, requestGet, requestPost, requestPut } from 'app/utils/request';
import { HttpStatusCode } from 'axios';
/**
 * Handle get list customer review
 * @param {Object} value search content
 */
const search = async (value) => {
    const { searchField, sortField, sortOrder, first, rows } = value;
    const params = {
        first,
        searchField,
        rows,
        sortField,
        sortOrder: sortOrder === 1 ? SORT_ORDER.ASC : SORT_ORDER.DESC
    };
    const res = await requestGet(CUSTOMER_REVIEW_URL, { params }); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return data.payload;
    } else {
        return [];
    }
};

/* Request to create user data from server
 * @param {Object} data store customer review data
 */
const create = (data) => requestPost(CUSTOMER_REVIEW_URL, data);
/* Request to create user data from server
 * @param {Object} data store customer review data
 */
const update = (data) => requestPut(CUSTOMER_REVIEW_URL, data);
/* Request to create user data from server
 * @param {Object} data store customer review data
 */
const remove = (data) => requestDelete(CUSTOMER_REVIEW_URL, { data });
/* Request to create user data from server
 * @param {Object} data store customer review data
 */
export default { search, create, update, remove };
