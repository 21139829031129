import { blogService, commonService } from 'app/services';
import { t } from 'i18next';
import _ from 'lodash';
import moment from 'moment';
import { Image } from 'primereact/image';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import RightBlog from './right-blog';
export default function BlogDetail() {
    const [data, setData] = useState({});
    const location = useLocation();
    /**
     * fetch data from db with ID
     * @returns
     */
    const fetchData = async () => {
        commonService.preloader.show();
        const data = await blogService.detailBlog({ ID: location.state });
        setData(data);
        commonService.preloader.hide();
        return true;
    };
    const renderTag = () => {
        const list = [];
        if (_.isUndefined(data.TagList)) {
            return list;
        }
        for (const item of data.TagList) {
            list.push(
                <Button className='btn-tag mx-1' variant='default' key={`tag${item.ID}`}>
                    {item.Name}
                </Button>
            );
        }
        return list;
    };
    useEffect(() => {
        commonService.preloader.hide();
        fetchData();
    }, []);
    return (
        <div>
            <div className='blogs py-5 row'>
                <div className='col-md-8 col-sm-12'>
                    <h2>{data.Title}</h2>
                    <div className='my-3 border'>
                        <div className='blog-thumb'>
                            <Image src={`${process.env.PUBLIC_URL}/image/blogs/${data.ID}/${data.Images}`} preview />
                            <div className='blog-meta-top'>
                                <Button className='bnt-blog-category'>{data.CategoryName?.Name}</Button>
                            </div>
                        </div>
                        <div className='single-blog-details-inner'>
                            <div className='blog-meta-left pb-3 pt-5 px-4'>
                                <span>
                                    <i className='pi pi-user pe-2'></i>By: Admin
                                </span>
                                <span>
                                    <i className='pi pi-calendar ps-4 pe-2' aria-hidden='true'></i>
                                    {moment(data.CreatedAt).format('DD-MM-YYYY')}
                                </span>
                                <span>
                                    <i className='pi pi-comments ps-4 pe-2' aria-hidden='true'></i>0 Comments
                                </span>
                            </div>
                            <div className='px-4'>
                                <div className='single-blog-content'>
                                    <div dangerouslySetInnerHTML={{ __html: data.Content }}></div>
                                </div>
                            </div>
                        </div>
                        <div className='row mx-0 px-4'>
                            <Button variant='social'>
                                <span className='pi pi-twitter' />
                            </Button>
                            <Button variant='social'>
                                <span className='pi pi-instagram' />
                            </Button>
                            <Button variant='social'>
                                <span className='pi pi-facebook' />
                            </Button>
                        </div>
                        <div className='post-details-footer'>
                            <div className='widget bg-transparent mb-0 pb-0'>
                                <h5 className='widget-title-detail mb-3'>{t('LABEL_BLOG_CATE')} </h5>
                                <div className='btn-list'>
                                    <Button className='bnt-blog-category'>{data.CategoryName?.Name}</Button>
                                </div>
                            </div>
                            <div className='widget bg-transparent mb-0'>
                                <h5 className='widget-title-detail mb-3'>{t('LABEL_BLOG_TAG')} </h5>
                                <div className='btn-list'>{renderTag()}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <RightBlog />
            </div>
        </div>
    );
}
