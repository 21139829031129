import {
    BATHROOM_LIST,
    BEDROOM_LIST,
    DISABILITY_LIST,
    ENTERTAINMENT_LIST,
    FAMILY_LIST,
    FOOD_LIST,
    HOTEL,
    INTERNET_LIST,
    MORE_LIST,
    ROUTER
} from 'app/constants';
import { commonService } from 'app/services';
import roomService from 'app/services/room/room.service';
import { HttpStatusCode } from 'axios';
import { t } from 'i18next';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { redirect } from 'react-router-dom';
export default function AddRoom() {
    const [Name, setName] = useState('');
    const [View, setView] = useState('');
    const [Beds, setBeds] = useState(0);
    const [selectedHotel, setSelectedHotel] = useState(1);
    const [PriceBreakfast, setPriceBreakfast] = useState(0);
    const [PriceNoBreakfast, setPriceNoBreakfast] = useState(0);
    const [PriceSaleBreakfast, setPriceSaleBreakfast] = useState(0);
    const [PriceSaleNoBreakfast, setPriceSaleNoBreakfast] = useState(0);
    const [Adults, setAdults] = useState(0);
    const [Acreage, setAcreage] = useState(0);
    const [BathRoom, setBathRoom] = useState('');
    const [SortDescription, setSortDescription] = useState('');
    const [Description, setDescription] = useState('');
    const [selectedDisability, setSelectedDisability] = useState(DISABILITY_LIST.map((v) => v.value));
    const [selectedBathroom, setSelectedBathroom] = useState(BATHROOM_LIST.map((v) => v.value));
    const [selectedBedroom, setSelectedBedroom] = useState(BEDROOM_LIST.map((v) => v.value));
    const [selectedEntertainment, setSelectedEntertainment] = useState(ENTERTAINMENT_LIST.map((v) => v.value));
    const [selectedInternet, setSelectedInternet] = useState(INTERNET_LIST.map((v) => v.value));
    const [selectedFood, setSelectedFood] = useState(FOOD_LIST.map((v) => v.value));
    const [selectedFamily, setSelectedFamily] = useState(FAMILY_LIST.map((v) => v.value));
    const [selectedMore, setSelectedMore] = useState(MORE_LIST.map((v) => v.value));
    /**
     * Add room
     */
    const add = async () => {
        const res = await roomService.create({
            Category: selectedHotel,
            Name,
            Description,
            Price: {
                Norm: PriceNoBreakfast,
                Sale: PriceSaleNoBreakfast
            },
            PriceBreakFast: {
                Norm: PriceBreakfast,
                Sale: PriceSaleBreakfast
            },
            RoomAmenities: {
                bathroom: selectedBathroom,
                bedroom: selectedBedroom,
                disability: selectedDisability,
                entertainment: selectedEntertainment,
                family: selectedFamily,
                food: selectedFood,
                internet: selectedInternet,
                main: {
                    bed: Beds,
                    blueprint: View,
                    direction: Acreage,
                    people: Adults
                },
                more: selectedMore
            },
            SpecialDescription: SortDescription
        });
        if (res.status === HttpStatusCode.Ok) {
            commonService.toast.show({
                severity: 'success',
                summary: t('INFO_MESSAGE'),
                detail: t('M019')
            });
            redirect(ROUTER.ROOM);
        } else {
            commonService.toast.show({
                severity: 'error',
                summary: t('ERROR_MESSAGE'),
                detail: t('M009')
            });
        }
    };
    return (
        <div>
            <div className='auto-container row container-rooms pb-4'>
                <div className='col-12'>
                    <h2 className='fw-bold'>Add Room</h2>
                    <div className='row'>
                        <div className='col-md-4 py-2'>
                            <label htmlFor='name'>Name</label>
                            <InputText value={Name} onChange={(e) => setName(e.target.value)} className='w-100' id='name' />
                        </div>
                        <div className='col-md-4 py-2'>
                            <label htmlFor='name'>Hotel</label>
                            <Dropdown
                                value={selectedHotel}
                                onChange={(e) => setSelectedHotel(e.value)}
                                options={HOTEL}
                                placeholder='Select a Hotel'
                                className='w-100'
                            />
                        </div>
                        <div className='col-md-4 py-2'>
                            <label htmlFor='view'>View</label>
                            <InputText value={View} onChange={(e) => setView(e.target.value)} className='w-100' id='view' />
                        </div>
                        <div className='col-md-6 py-2'>
                            <label htmlFor='sortDescription'>Sort Description</label>
                            <InputTextarea
                                value={SortDescription}
                                onChange={(e) => setSortDescription(e.target.value)}
                                className='w-100'
                                id='sortDescription'
                                rows={5}
                                cols={30}
                            />
                        </div>
                        <div className='col-md-6 py-2'>
                            <label htmlFor='description'>Description</label>
                            <InputTextarea
                                value={Description}
                                onChange={(e) => setDescription(e.target.value)}
                                className='w-100'
                                id='description'
                                rows={5}
                                cols={30}
                            />
                        </div>
                        <div className='col-md-3 py-2'>
                            <label htmlFor='priceNo'>Beds</label>
                            <InputText value={Beds} onChange={(e) => setBeds(e.target.value)} className='w-100' id='priceNo' />
                        </div>
                        <div className='col-md-3 py-2'>
                            <label htmlFor='bathroom'>Bathroom Type</label>
                            <InputText value={BathRoom} onChange={(e) => setBathRoom(e.target.value)} className='w-100' id='bathroom' />
                        </div>
                        <div className='col-md-3 py-2'>
                            <label htmlFor='priceBreakfast'>Price Breakfast (VND)</label>
                            <InputNumber
                                value={PriceBreakfast}
                                onChange={(e) => setPriceBreakfast(e.value)}
                                className='w-100'
                                id='priceBreakfast'
                            />
                        </div>
                        <div className='col-md-3 py-2'>
                            <label htmlFor='priceNo'>Price No Breakfast(VND)</label>
                            <InputNumber
                                value={PriceNoBreakfast}
                                onChange={(e) => setPriceNoBreakfast(e.value)}
                                className='w-100'
                                id='priceNo'
                            />
                        </div>
                        <div className='col-md-3 py-2'>
                            <label htmlFor='adults'>Adults</label>
                            <InputNumber value={Adults} onChange={(e) => setAdults(e.value)} className='w-100' id='adults' />
                        </div>
                        <div className='col-md-3 py-2'>
                            <label htmlFor='acreage'>Acreage</label>
                            <InputNumber value={Acreage} onChange={(e) => setAcreage(e.value)} className='w-100' id='acreage' />
                        </div>
                        <div className='col-md-3 py-2'>
                            <label htmlFor='pricesNo'>Price No Breakfast (Sale)(VND)</label>
                            <InputNumber
                                value={PriceSaleNoBreakfast}
                                onChange={(e) => setPriceSaleNoBreakfast(e.value)}
                                className='w-100'
                                id='pricesNo'
                            />
                        </div>
                        <div className='col-md-3 py-2'>
                            <label htmlFor='pricesBreakfast'>Price Breakfast (Sale)(VND)</label>
                            <InputNumber
                                value={PriceSaleBreakfast}
                                onChange={(e) => setPriceSaleBreakfast(e.value)}
                                className='w-100'
                                id='pricesBreakfast'
                            />
                        </div>
                        <div className='col-md-6 py-2'>
                            <label htmlFor='disability'>Disability</label>
                            <MultiSelect
                                value={selectedDisability}
                                onChange={(e) => setSelectedDisability(e.value)}
                                options={DISABILITY_LIST}
                                maxSelectedLabels={3}
                                id='disability'
                                className='w-100'
                            />
                        </div>
                        <div className='col-md-6 py-2'>
                            <label htmlFor='bathroom'>Bathroom</label>
                            <MultiSelect
                                value={selectedBathroom}
                                onChange={(e) => setSelectedBathroom(e.value)}
                                options={BATHROOM_LIST}
                                maxSelectedLabels={3}
                                id='bathroom'
                                className='w-100'
                            />
                        </div>
                        <div className='col-md-6 py-2'>
                            <label htmlFor='bedroom'>Bedroom</label>
                            <MultiSelect
                                value={selectedBedroom}
                                onChange={(e) => setSelectedBedroom(e.value)}
                                options={BEDROOM_LIST}
                                maxSelectedLabels={3}
                                id='bedroom'
                                className='w-100'
                            />
                        </div>
                        <div className='col-md-6 py-2'>
                            <label htmlFor='entertainment'>Entertainment</label>
                            <MultiSelect
                                value={selectedEntertainment}
                                onChange={(e) => setSelectedEntertainment(e.value)}
                                options={ENTERTAINMENT_LIST}
                                maxSelectedLabels={3}
                                id='entertainment'
                                className='w-100'
                            />
                        </div>
                        <div className='col-md-6 py-2'>
                            <label htmlFor='family'>Family</label>
                            <MultiSelect
                                value={selectedFamily}
                                onChange={(e) => setSelectedFamily(e.value)}
                                options={FAMILY_LIST}
                                maxSelectedLabels={3}
                                id='family'
                                className='w-100'
                            />
                        </div>
                        <div className='col-md-6 py-2'>
                            <label htmlFor='food'>Food and Drink</label>
                            <MultiSelect
                                value={selectedFood}
                                onChange={(e) => setSelectedFood(e.value)}
                                options={FOOD_LIST}
                                maxSelectedLabels={3}
                                id='food'
                                className='w-100'
                            />
                        </div>
                        <div className='col-md-6 py-2'>
                            <label htmlFor='internet'>Internet</label>
                            <MultiSelect
                                value={selectedInternet}
                                onChange={(e) => setSelectedInternet(e.value)}
                                options={INTERNET_LIST}
                                maxSelectedLabels={3}
                                id='internet'
                                className='w-100'
                            />
                        </div>
                        <div className='col-md-6 py-2'>
                            <label htmlFor='more'>More</label>
                            <MultiSelect
                                value={selectedMore}
                                onChange={(e) => setSelectedMore(e.value)}
                                options={MORE_LIST}
                                maxSelectedLabels={3}
                                id='more'
                                className='w-100'
                            />
                        </div>
                        <div className='row mx-0 py-4 d-flex justify-content-center align-items-center'>
                            <Button className='btn-cancel-sm mx-2' variant='default' onClick={() => add()}>
                                Add
                            </Button>
                            <Button className='btn-action-sm mx-2' variant='default'>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
