import { commonService, fileManagerService } from 'app/services';
import roomService from 'app/services/room/room.service';
import { t } from 'app/translations';
import { HttpStatusCode } from 'axios';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
export default function ImageHotel() {
    const location = useLocation();
    const [allData, setData] = useState({});
    /**
     * fetch data from db with ID
     * @returns
     */
    const fetchData = async () => {
        commonService.preloader.show();
        const data = await roomService.details({ ID: location.state });
        setData(data);
        commonService.preloader.hide();
        return true;
    };
    /**
     * Handle Delete Action
     * @param {Number} ID ID action
     */
    const remove = async (ID, file) => {
        const result = await commonService.modal.showConfirm({
            header: t('LABEL_DELETE_ITEM'),
            message: t('M016'),
            img: 'delete-message',
            customClass: 'warning-confirm-delete px-0'
        });
        if (result) {
            commonService.preloader.show();
            const res = await roomService.removeImage({ ID, file });
            if (res.status === HttpStatusCode.Ok) {
                commonService.toast.show({
                    severity: 'success',
                    summary: t('INFO_MESSAGE'),
                    detail: t('M018')
                });
                fetchData();
                commonService.preloader.hide();
                return true;
            }
            commonService.preloader.hide();
            return commonService.toast.show({
                severity: 'error',
                summary: t('ERROR_MESSAGE'),
                detail: t('M017')
            });
        }
    };
    useEffect(() => {
        commonService.preloader.hide();
        fetchData();
    }, []);
    const renderImage = () => {
        const list = [];
        for (const item in allData.ImageList) {
            const value = (
                <div className='col-lg-4 room-block-two height-400 py-3' key={item}>
                    <img src={`${process.env.PUBLIC_URL}/image/room/${location.state}/${allData.ImageList[item]}`} alt='' />
                    <div className='row mx-0 py-2 justify-content-end'>
                        <Button
                            className='btn-action-sm w-80px delete-img'
                            variant='default'
                            onClick={() => remove(location.state, allData.ImageList[item])}>
                            Delete
                        </Button>
                    </div>
                </div>
            );
            list.push(value);
        }
        return <div className='position-relative row mx-0'>{list.length === 0 ? t('M008') : list}</div>;
    };
    const onUpload = () => {
        const file = document.createElement('INPUT');
        file.setAttribute('type', 'file');
        file.accept = 'image/x-png, image/gif, image/jpeg, image/webp';
        file.setAttribute('multiple', true);
        file.onchange = handleUpload;
        file.click();
    };
    const handleUpload = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const files = event.target.files || event.dataTransfer.files;
        for await (const file of files) {
            const data = new FormData();
            data.append('ID', location.state);
            data.append('files', file);
            await fileManagerService.upload(data);
        }
        commonService.toast.show({
            severity: 'success',
            summary: t('INFO_MESSAGE'),
            detail: t('M007')
        });
        fetchData();
    };
    return (
        <div className='px-3 rooms h-100'>
            <div className='col-12 row mx-0 py-2'>
                <div className='w-75 py-2 row mx-0 px-0'>
                    <h1 className='my-0 fs-xxl fw-bold text-primary w-100 px-0 align-items-center d-flex'>{allData.Name}</h1>
                </div>
                <div className='w-25 px-0 d-flex justify-content-end'>
                    <Button key='Upload' variant='secondary' onClick={() => onUpload()} className='btn-action-sm'>
                        Add Image
                    </Button>
                </div>
            </div>
            <div className='col-12 row mx-0 py-2'>{renderImage()}</div>
        </div>
    );
}
