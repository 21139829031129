/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { GROUP, ROUTER } from 'app/constants';
import { blogService, categoryService, commonService, fileManagerService } from 'app/services';
import { useRedirect } from 'app/utils/browser';
import MultiSelectModify from 'app/views/common/multi-select';
import DefaultImage from 'assets/image/default.png';
import { HttpStatusCode } from 'axios';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
export default function Blog() {
    const { t } = useTranslation();
    const inputSearch = useRef();
    const [searchField, setSearchField] = useState('');
    const [optionCategory, setOptionCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [oldFilter, setOldFilter] = useState([]);
    const [data, setData] = useState([]);
    const redirect = useRedirect();
    let currentID = '';
    // const navigate = useNavigate();
    /**
     * fetch data from api
     * @param {Object} input
     * @returns
     */
    const fetchData = async (input) => {
        commonService.preloader.show();
        const payload = {
            searchField: searchField.trim(),
            selectedCategory,
            ...input
        };
        const data = await blogService.search(payload);
        setData(data.data);
        commonService.preloader.hide();
        return true;
    };
    /**
     * Handle Delete Action
     * @param {Number} ID ID action
     */
    const remove = async (ID) => {
        const result = await commonService.modal.showConfirm({
            header: t('LABEL_DELETE_ITEM'),
            message: t('M016'),
            img: 'delete-message',
            customClass: 'warning-confirm-delete px-0'
        });
        if (result) {
            commonService.preloader.show();
            const res = await blogService.remove({ ID });
            if (res.status === HttpStatusCode.Ok) {
                commonService.toast.show({
                    severity: 'success',
                    summary: t('INFO_MESSAGE'),
                    detail: t('M018')
                });
                fetchData();
                commonService.preloader.hide();
                return true;
            }
            commonService.preloader.hide();
            return commonService.toast.show({
                severity: 'error',
                summary: t('ERROR_MESSAGE'),
                detail: t('M017')
            });
        }
    };
    /**
     * On hide filter
     */
    const onHideFilter = async () => {
        if (selectedCategory !== oldFilter) {
            await fetchData();
            setOldFilter(selectedCategory);
        }
    };
    /**
     * re-load table and execute function to search data
     * @function fetchData Handle and display data on table
     */
    const onSearch = (e) => {
        e.preventDefault();
        fetchData();
    };
    /**
     * Fetch first render data
     */
    useEffect(() => {
        commonService.preloader.hide();
        getCategory();
        inputSearch.current.focus();
    }, []);

    // eslint-disable-next-line no-unused-vars
    const onUpload = () => {
        const file = document.createElement('INPUT');
        file.setAttribute('type', 'file');
        file.accept = 'image/x-png, image/gif, image/jpeg, image/webp';
        file.setAttribute('multiple', true);
        file.onchange = handleUpload;
        file.click();
    };
    const handleUpload = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const files = event.target.files || event.dataTransfer.files;
        for await (const file of files) {
            const data = new FormData();
            data.append('Type', GROUP.BLOG);
            data.append('Category', currentID);
            data.append('files', file);
            await fileManagerService.upload(data);
        }
        commonService.toast.show({
            severity: 'success',
            summary: t('INFO_MESSAGE'),
            detail: t('M007')
        });
        fetchData();
    };
    const getCategory = async () => {
        const data = await categoryService.search({ searchField: '', sortField: 'UpdatedAt', sortOrder: 1, first: 0, rows: 1000 });
        setOptionCategory(data.data);
        setOldFilter(data.data.map((v) => v.ID));
        fetchData({ selectedCategory: data.data.map((v) => v.ID) });
        setSelectedCategory(data.data.map((v) => v.ID));
    };
    const tour = () => {
        const list = [];
        for (const item of data) {
            const value = (
                <div className='col-md-4 col-sm-12 px-1 py-3' key={item.ID}>
                    <div className='mx-2'>
                        <div className='h-img-blog'>
                            <Image
                                src={
                                    !_.isNull(item.Images)
                                        ? `${process.env.PUBLIC_URL}/image/blogs/${item.ID}/${item.Images}`
                                        : DefaultImage
                                }
                                alt=''
                                preview
                            />
                        </div>
                        <div className='events-body h-body-blog px-3'>
                            <div className='entity-header'>
                                <h2 className='event-title pt-3'>
                                    <a
                                        href='#'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            redirect(generatePath(ROUTER.BLOG_DETAIL), ROUTER.ID, { id: item.ID }, item.ID);
                                        }}>
                                        {item.Title}
                                    </a>
                                </h2>
                            </div>
                            <div className='pt-2'>
                                <p className='entity-body text-ellipsis-3line'>{item.SpecialContent}</p>
                            </div>
                            <div className='row mx-0'>
                                <Button
                                    className='btn-cancel-sm w-80px'
                                    variant='default'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redirect(generatePath(ROUTER.EDIT_BLOG), ROUTER.ID, { id: item.ID }, item.ID);
                                    }}>
                                    {t('BTN_UPDATE')}
                                </Button>
                                <Button
                                    className='btn-cancel-sm w-80px mx-2'
                                    variant='default'
                                    onClick={(e) => {
                                        currentID = item.ID;
                                        e.preventDefault();
                                        onUpload();
                                    }}>
                                    {t('BTN_EDIT_IMAGE')}
                                </Button>
                                <Button className='btn-action-sm w-80px' variant='default' onClick={() => remove(item.ID)}>
                                    {t('LABEL_TOOLTIP_ICON_REMOVE')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
            list.push(value);
        }
        return list;
    };
    return (
        <div className='events px-3'>
            <div className='row'>
                <div className='col-12 row mx-0 py-2'>
                    <div className='w-30 py-2 row mx-0 px-0'>
                        <h1 className='my-0 fs-xxl fw-bold text-primary w-100 px-0 align-items-center d-flex'>{t('LABEL_BLOGS')}</h1>
                    </div>
                    <div className='w-25 px-0 h-40-px'>
                        <form onSubmit={onSearch} className='position-relative width-input' autoComplete='off'>
                            <InputText
                                placeholder={t('PLACEHOLDER_ENTER_KEY_SEARCH')}
                                className='ps-3 w-100'
                                value={searchField}
                                ref={inputSearch}
                                onChange={(e) => setSearchField(e.target.value)}
                            />
                            <Button variant='custom-inline' type='submit'>
                                <span className='icon search fs-md icon-search'></span>
                            </Button>
                        </form>
                    </div>
                    <div className='w-20 px-3'>
                        <MultiSelectModify
                            className='w-100 fs-md ms-3 multi-select-systems'
                            labelMaxSelectedItem={t('LABEL_ALL_CATE')}
                            value={selectedCategory}
                            onChange={(e) => {
                                setSelectedCategory(e.value);
                            }}
                            onHide={() => {
                                onHideFilter();
                            }}
                            optionLabel='Name'
                            optionValue='ID'
                            options={optionCategory}
                            maxSelectedLabels={3}
                            id='disability'
                        />
                    </div>
                    <div className='w-20 px-0 d-flex justify-content-end'>
                        <a href={ROUTER.ADD_BLOG} className='btn-action-sm px-4'>
                            {t('BTN_ADD_BLOG')}
                        </a>
                    </div>
                    {tour()}
                </div>
            </div>
        </div>
    );
}
