import { GROUP, SERVICE_TYPE } from 'app/constants';
import { commonService, fileManagerService } from 'app/services';
import serviceService from 'app/services/service/service.service';
import { t } from 'app/translations';
import { HttpStatusCode } from 'axios';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
export default function Spa() {
    const [Content, setContent] = useState('');
    const [Location, setLocation] = useState('');
    const [Operating, setOperating] = useState('');
    const [ImageList, setImageList] = useState([]);

    /**
     * fetch data from db with ID
     * @returns
     */
    const fetchData = async () => {
        commonService.preloader.show();
        const data = await serviceService.get({ type: GROUP.SERVICE, category: SERVICE_TYPE.SPA });
        setContent(data.Content);
        setOperating(data.Operating);
        setLocation(data.Location);
        setImageList(data.ImageList);
        commonService.preloader.hide();
        return true;
    };
    /**
     * Update user
     */
    const edit = async () => {
        const res = await serviceService.update({
            Type: GROUP.SERVICE,
            Category: SERVICE_TYPE.SPA,
            Content,
            Location,
            Operating
        });
        if (res.status === HttpStatusCode.Ok) {
            commonService.toast.show({
                severity: 'success',
                summary: t('INFO_MESSAGE'),
                detail: t('M012')
            });
            fetchData();
        } else {
            commonService.toast.show({
                severity: 'error',
                summary: t('ERROR_MESSAGE'),
                detail: t('M011')
            });
        }
    };
    /**
     * Handle Delete Action
     * @param {Number} ID ID action
     */
    const remove = async (file) => {
        const result = await commonService.modal.showConfirm({
            header: t('LABEL_DELETE_ITEM'),
            message: t('M016'),
            img: 'delete-message',
            customClass: 'warning-confirm-delete px-0'
        });
        if (result) {
            commonService.preloader.show();
            const res = await serviceService.removeImage({ type: GROUP.SERVICE, category: SERVICE_TYPE.SPA, file });
            if (res.status === HttpStatusCode.Ok) {
                commonService.toast.show({
                    severity: 'success',
                    summary: t('INFO_MESSAGE'),
                    detail: t('M018')
                });
                fetchData();
                commonService.preloader.hide();
                return true;
            }
            commonService.preloader.hide();
            return commonService.toast.show({
                severity: 'error',
                summary: t('ERROR_MESSAGE'),
                detail: t('M017')
            });
        }
    };
    useEffect(() => {
        commonService.preloader.hide();
        fetchData();
    }, []);
    const renderImage = () => {
        const list = [];
        for (const item in ImageList) {
            const value = (
                <div className='col-lg-4 room-block-two height-400 py-3' key={item}>
                    <Image src={`${process.env.PUBLIC_URL}/image/service/${SERVICE_TYPE.SPA}/${ImageList[item]}`} alt='Image' preview />
                    <div className='row mx-0 py-2 justify-content-end'>
                        <Button className='btn-action-sm w-80px delete-img' variant='default' onClick={() => remove(ImageList[item])}>
                            {t('LABEL_TOOLTIP_ICON_REMOVE')}
                        </Button>
                    </div>
                </div>
            );
            list.push(value);
        }
        return <div className='position-relative row mx-0'>{list.length === 0 ? t('M008') : list}</div>;
    };
    const onUpload = () => {
        const file = document.createElement('INPUT');
        file.setAttribute('type', 'file');
        file.accept = 'image/x-png, image/gif, image/jpeg, image/webp';
        file.setAttribute('multiple', true);
        file.onchange = handleUpload;
        file.click();
    };
    const handleUpload = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const files = event.target.files || event.dataTransfer.files;
        for await (const file of files) {
            const data = new FormData();
            data.append('Type', GROUP.SERVICE);
            data.append('Category', SERVICE_TYPE.SPA);
            data.append('files', file);
            await fileManagerService.upload(data);
        }
        commonService.toast.show({
            severity: 'success',
            summary: t('INFO_MESSAGE'),
            detail: t('M007')
        });
        fetchData();
    };
    useEffect(() => {
        commonService.preloader.hide();
        fetchData();
    }, []);
    return (
        <div>
            <div className='auto-container row container-rooms pb-4'>
                <div className='col-12'>
                    <div className='row'>
                        <h2 className='fw-bold'>{t('LABEL_SPA_MESSAGE')}</h2>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 py-2'>
                            <label htmlFor='name'>{t('LABEL_LOCATION')}</label>
                            <InputText value={Location} onChange={(e) => setLocation(e.target.value)} className='w-100' id='name' />
                        </div>
                        <div className='col-md-6 py-2'>
                            <label htmlFor='view'>{t('LABEL_OPERATING_OPEN')}</label>
                            <InputText value={Operating} onChange={(e) => setOperating(e.target.value)} className='w-100' id='view' />
                        </div>
                        <div className='col-md-12 py-2'>
                            <label htmlFor='content'>{t('TABLE_LABEL_CONTENT')}</label>
                            <InputTextarea
                                value={Content}
                                onChange={(e) => setContent(e.target.value)}
                                className='w-100'
                                id='content'
                                rows={5}
                                cols={30}
                            />
                        </div>
                        <div className='row mx-0 py-4 d-flex justify-content-center align-items-center'>
                            <Button className='btn-cancel-sm mx-2' variant='default' onClick={() => edit()}>
                                {t('BTN_SAVE')}
                            </Button>
                            <Button className='btn-action-sm mx-2' variant='default'>
                                {t('BTN_CANCEL')}
                            </Button>
                        </div>
                    </div>
                    <div className='col-12 row mx-0 py-2'>
                        <div className='w-75 py-2 row mx-0 px-0'>
                            <h1 className='my-0 fs-xxl fw-bold text-primary w-100 px-0 align-items-center d-flex'>
                                {t('LABEL_LIST_IMAGE')}
                            </h1>
                        </div>
                        <div className='w-25 px-0 d-flex justify-content-end'>
                            <Button key='Upload' variant='secondary' onClick={() => onUpload()} className='btn-action-sm'>
                                {t('BTN_ADD_IMAGE')}
                            </Button>
                        </div>
                    </div>
                    <div className='col-12 row mx-0 py-2'>{renderImage()}</div>
                </div>
            </div>
        </div>
    );
}
